import styled from "styled-components";

export default function Logo() {
    return (
        <LogoWrapper><h1>Lee</h1></LogoWrapper>
    )
}

const LogoWrapper = styled.div`
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        font-size: 38px;
        font-weight: bold;
        margin: auto;
        background: linear-gradient(90deg, #FF0075, #1E90FF, #32CD32);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-family: 'Poppins', sans-serif;
    }
`;
